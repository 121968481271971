/* content area box */
.BodyBg{
    background-color: #F6F8FC !important;
}
@font-face {
  font-family: 'Calibri';
  src: url('./components/calibri.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibri';
  src: url('./components/calibri-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.contentSide {
    height: 650px !important;
    width: 95%;
}
body, input, textarea, select, button, p, h1, h2, h3, h4, h5, h6, span, a {
    font-family: 'Calibri', sans-serif;
}
.mermaid .node rect {
    fill: none;
    stroke: none;
}

.mermaid .node circle {
    size: 50; /* Increase the radius of circles */
    fill: black;
    stroke: black;
    stroke-width: 5px;
}

.mermaid .node text {
    font-size: 12px;
    fill: black;
    text-anchor: middle;
}

.mermaid .node text:hover {
    font-weight: bold;
}

.mermaid .node circle.green {
    fill: green;
}

.mermaid .node circle.blue {
    fill: blue;
}

.mermaid .node circle.red {
    fill: red;
}
.logo {
    top: 50%;
    left: 50%;
    animation: spin-scale 3s ease-in-out forwards;
    opacity: 0; /* Start hidden */
}

/* Keyframes for smooth rotation, scaling, and staying visible */
@keyframes spin-scale { 
    0% {
        transform: rotate(0deg) scale(0);
        opacity: 0; /* Start hidden */
    }
    20% {
        opacity: 1; /* Fade in */
    }
    60% {
        transform: rotate(360deg) scale(1.2); /* Rotate and scale up */
    }
    80% {
        transform: rotate(360deg) scale(1); /* Return to original size */
    }
    100% {
        transform: rotate(360deg) scale(1); /* Stay at original size */
        opacity: 1; /* Remain visible */
    }
}
.login-fields-container {
    opacity: 0;
    pointer-events: none;
}

.login-fields {
    animation: login-fields 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
    will-change: transform, opacity; /* Hint for smoother animation */
}

@keyframes login-fields {
    0% {
        opacity: 0;
        transform: translateY(30px); /* Smaller distance for smoother feel */
    }
    60% {
        opacity: 0.8;
        transform: translateY(10px); /* Smooth transition */
    }
    100% {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    }
}

/* Chose account type */
@keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .choseAccount {
    opacity: 0;
    animation: slideInFromLeft 0.5s forwards;
  }
  
  .choseAccount:nth-child(1) {
    animation-delay: 0.1s;
  }
  .choseAccount:nth-child(2) {
    animation-delay: 0.2s;
  }
  .choseAccount:nth-child(3) {
    animation-delay: 0.3s;
  }
  .choseAccount:nth-child(4) {
    animation-delay: 0.4s;
  }


  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(10px);
    }
  }
  .course-card {
    /* padding: 20px; */
    /* border: 1px solid gray; */
    border-radius: 20px;
    width: '100%';
    background-color: #ACCCE5;
    /* text-align: center; */
    /* transition: background-color 0.3s ease; */
  }
  /* apple font */
  @font-face {
    font-family: 'Calibri';
    src: url('./components/calibri-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
  }




